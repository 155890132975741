import Vue from "vue";
import Vuex from "vuex";
import VueJwtDecode from "vue-jwt-decode";
import {JSEncrypt} from "jsencrypt";

Vue.use(Vuex);

const decrypt_data = (data) => {
    let data_splited = data.split("|");
    let private_key =
        "-----BEGIN RSA PRIVATE KEY-----\n" +
        data_splited[0] +
        "-----END RSA PRIVATE KEY-----";
    let cipherText = data_splited[1];
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(private_key);
    const plainText = decrypt.decrypt(cipherText) || "DECRYPTION FAILED";
    console.log(plainText);
    return plainText;
};

const store = new Vuex.Store({
    state: {
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        permissions: [],
        group: null,
        user: null,
        validTill: null,
        baseURL: process.env.VUE_APP_DJANGO_BASE_URL,
        // baseURL: "/api",
        // baseURL: "http://10.0.250.70:8000/api",
        // baseURL: "http://103.132.94.116/api",
        passreset: true,
        snackbar_msg: {
            text: "",
            show: false,
        },
        branch_data: null,
        server_time: null,
        current_period: null,
    },
    mutations: {
        saveStore(state) {
            localStorage.setItem("isAuthenticated", state.isAuthenticated);
            localStorage.setItem("accessToken", state.accessToken);
            // localStorage.setItem("refreshToken", state.refreshToken);
            localStorage.setItem("validTill", state.validTill);
        },
        initializeStore(state) {
            if (localStorage.getItem("isAuthenticated") === true) {
                state.isAuthenticated = true;
            }
        },
        authenticate(state, auth_status) {
            state.isAuthenticated = auth_status;
        },
        setJWT(state, token) {
            let access = decrypt_data(token.access);
            // let refresh = decode_data(token.refresh);
            state.accessToken = access;
            // state.refreshToken = refresh;
            // console.log(state.accessToken);
            // console.log(state.refreshToken);
            let jwt = VueJwtDecode.decode(state.accessToken);
            state.validTill = jwt.exp;
            state.server_time = Date.parse(token.time) / 1000;
        },
        setPermissions(state, permissions) {
            state.permissions = [...permissions];
        },
        setGroup(state, group) {
            state.group = group;
        },
        setUser(state, data) {
            // console.log('user data: ', data.user)
            state.user = data.name;
            state.branch_data = data.branch
            // console.log('user data: ', state.user_data)
        },
        refreshToken(state, token) {
            let access = decrypt_data(token.access);
            state.accessToken = access;
            let jwt = VueJwtDecode.decode(state.accessToken);
            state.validTill = jwt.exp;
            state.server_time = Date.parse(token.time) / 1000;
        },
        setPassReset(state, status) {
            state.passreset = status;
        },
        setLogout(state) {
            state.accessToken = null;
            state.passreset = true;
            state.permissions = [];
            state.isAuthenticated = false;
            state.group = null;
            state.user = null;
            state.validTill = null;
            state.current_period = null;
            console.log(state);
        },
        increaseServerTime(state, time) {
            state.server_time += time;
            // console.log(state.server_time);
        },
        update_snackbar(state, status) {
            state.snackbar_msg.text = status.text;
            state.snackbar_msg.show = status.status;
        },
        setCurrentPeriod(state, period) {
            state.current_period = period;
        },
    },
    actions: {},
    getters: {
        permissionCheckers: (state) => (perm) => {
            return state.permissions.find((x) => x === perm);
        },
    },
    modules: {},
});

export default store;
