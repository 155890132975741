const app_setting_routes = [
    {
        path: "/app_setting/cause",
        name: "Cause",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "Cause" */ "@/views/administration/app_settings/CausePage"
                ),
    },
    {
        path: "/app_setting/district",
        name: "District",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "District" */ "@/views/administration/app_settings/DistrictPage"
                ),
    },
    {
        path: "/app_setting/loan_type",
        name: "LoanType",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanType" */ "@/views/administration/app_settings/LoanTypePage"
                ),
    },
    {
        path: "/app_setting/court_type",
        name: "CourtType",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "CourtType" */ "@/views/administration/app_settings/CourtTypePage"
                ),
    },
    {
        path: "/app_setting/court",
        name: "Court",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "Court" */ "@/views/administration/app_settings/CourtPage"
                ),
    },
    {
        path: "/app_setting/case_type",
        name: "CaseType",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "CaseType" */ "@/views/administration/app_settings/CaseTypePage"
                ),
    },
    {
        path: "/app_setting/case_filed_by",
        name: "CaseFiledBy",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "CaseFiledBy" */ "@/views/administration/app_settings/CaseFiledByPage"
                ),
    },
    {
        path: "/app_setting/disposed_process",
        name: "DisposalProcess",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "DisposalThrough" */ "@/views/administration/app_settings/DisposalProcessPage"
                ),
    },
    {
        path: "/app_setting/execution_type",
        name: "ExecutionType",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ExecutionType" */ "@/views/administration/app_settings/ExecutionTypePage"
                ),
    },
    {
        path: "/app_setting/expense",
        name: "Expense",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "Expense" */ "@/views/administration/app_settings/ExpensePage"
                ),
    },
    {
        path: "/app_setting/mode_of_recovery",
        name: "ModeOfRecovery",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ModeOfRecovery" */ "@/views/administration/app_settings/ModeOfRecoveryPage"
                ),
    },
    {
        path: "/app_setting/nature_of_disposal",
        name: "NatureOfDisposal",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "NatureOfDisposal" */ "@/views/administration/app_settings/NatureOfDisposalPage"
                ),
    },
    {
        path: "/app_setting/purpose",
        name: "Purpose",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "Purpose" */ "@/views/administration/app_settings/PurposePage"
                ),
    },
    {
        path: "/app_setting/result_of_disposal",
        name: "ResultOfDisposal",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ResultOfDisposal" */ "@/views/administration/app_settings/ResultOfDisposalPage"
                ),
    },
    {
        path: "/app_setting/direction_bindig",
        name: "DirectionBinding",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "DirectionBinding" */ "@/views/administration/app_settings/DirectionPage"
                ),
    },
];

export default app_setting_routes;
