<template>
  <v-container>
    <h1 class="text-center font-weight-black text-uppercase">Agrani Bank Legal Matter System</h1>

    <v-row
        justify="center"
        align="center"
    >
      <v-col
          align-self="center"
          cols=5
          md="5"
      >
        <v-alert
            v-if="showAlert"
            shaped
            type="error"
            class="font-weight-black"
        >
          {{ alertMessage }}
        </v-alert>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent='loginHandler'
            autocomplete="nope"
        >
          <v-text-field
              v-model="username"
              label="User Name"
              required
              :rules="usernameRules"
          ></v-text-field>

          <v-text-field
              v-model="password"
              :type="passInputType"
              label="Password"
              :rules="passwordRules"
              required
          >
            <v-icon slot="append" @mousedown="handlePasswordInput" @mouseup="handlePasswordInput">
              mdi-eye
            </v-icon>
          </v-text-field>
          <div class="text-center">
            <v-btn
                rounded
                elevation="7"
                :loading="loading"
                type="submit"
            >
              <v-icon class="px-2">mdi-location-enter</v-icon>
              Log In
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import store from "@/store";
import period_urls from "@/constants/period_urls";


export default {
  name: 'Login',

  data: () => ({
    username: '',
    usernameRules: [
      v => !!v || 'Name is required',],
    password: '',
    passwordRules: [
      v => !!v || 'Password is required',],
    valid: true,
    loading: false,
    showAlert: false,
    alertMessage: 'Wrong User Name or Password!!!',
    passInputType: 'password',
    current_period_url: period_urls.monthly.current,
  }),
  computed: {
    axios_conf: () => {
      axios.defaults.baseURL = store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${store.state.accessToken}`
      return 0
    }
  },
  methods: {
    async loginHandler() {
      this.axios_conf
      // console.log(this.$refs.form.validate())
      let self = this
      if (this.$refs.form.validate()) {
        this.loading = true
        let data = {username: this.username, password: this.password}
        await axios.post('user/get_token', data)
            .then((response) => {
              // console.log(response.data.keys.access)
              if (response.data.keys) {
                this.$store.commit("authenticate", true)
                this.$store.commit('setJWT', response.data.keys)
                this.$store.commit('setPermissions', response.data.permissions)
                this.$store.commit('setGroup', response.data.group)
                // console.log(response.data)
                this.$store.commit('setUser', response.data)
                this.$store.commit('setPassReset', response.data.pass_reset)
                this.$router.push({name: 'Dashboard'})
                self.get_last_period()
              } else {
                this.alertMessage = 'Wrong User Name or Password'
                this.showAlert = true
              }
            })
            .catch((e) => {
              if (e.message == 'Network Error') {
                this.alertMessage = 'Network Error'
              } else {
                this.alertMessage = 'Wrong User Name or Password'
              }
              this.showAlert = true
            })
        this.loading = false
      }
    },
    get_last_period() {
      this.axios_conf
      // let config = {
      //   headers: {
      //     multi: "true"
      //   }
      // }
      axios.get(this.current_period_url).then((resp) => {
        this.$store.commit('setCurrentPeriod', resp.data.periods)
        this.$store.commit('setJWT', resp.data.key)
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
        this.$store.commit("update_snackbar", {
          text: "Unable to get current Period. Please check if the current period is initialized",
          status: true
        })
      })

    },
    handlePasswordInput() {
      if (this.passInputType === "password") {
        this.passInputType = "text"
      } else {
        this.passInputType = 'password'
      }
    }
  },
  // watch: {
  //   alertMessage: function (val) {
  //     console.log(val)
  //     return this.alertMessage = val
  //   }
  // }
}
</script>
