const cib_routes = [
    {
        path: "/subjects",
        name: "cib_subjects",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "Subjects" */ "@/views/administration/cib/subjects"
                ),
    },
    {
        path: "/loan_types",
        name: "LoanTypes",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanTypes" */ "@/views/administration/cib/LoanTypes"
                ),
    },
    {
        path: "/owner_types",
        name: "OwnerTypes",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "OwnerTypes" */ "@/views/administration/cib/OwnerTypes"
                ),
    },
    {
        path: "/loan_data",
        name: "LoanData",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanData" */ "@/views/administration/cib/LoanData"
                ),
    },
    {
        path: "/borrower_without_cib",
        name: "BorrowerWithoutCib",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "Subjects" */ "@/views/administration/cib/subjects"
                ),
    },

];

export default cib_routes;
