const Periods_urls = {
  list: "periods/yearly_periods/",
  get_a_yearly: "periods/yearly_periods/{id}/details",
  edit_a_yearly: "periods/yearly_periods/{id}/update",
  delete_a_yearly: "periods/yearly_periods/{id}/delete",
  authorize_a_yearly: "periods/yearly_periods/{id}/authorize",
  close_a_yearly: "periods/yearly_periods/{id}/close",
  half_yearly: {
    list: "periods/{parent}/half_yearly_periods/",
    get_half_yearly: "periods/{parent}/half_yearly_periods/{id}/details",
    edit_half_yearly: "periods/{parent}/half_yearly_periods/{id}/update",
    delete_half_yearly: "periods/{parent}/half_yearly_periods/{id}/delete",
    authorize_half_yearly:
      "periods/{parent}/half_yearly_periods/{id}/authorize",
    close_half_yearly: "periods/{parent}/half_yearly_periods/{id}/close",
  },
  quarterly: {
    list: "periods/{parent}/quarterly_periods/",
    get: "periods/{parent}/quarterly_periods/{id}/details",
    edit: "periods/{parent}/quarterly_periods/{id}/update",
    delete: "periods/{parent}/quarterly_periods/{id}/delete",
    authorize: "periods/{parent}/quarterly_periods/{id}/authorize",
    close: "periods/{parent}/quarterly_periods/{id}/close",
  },
  monthly: {
    list: "periods/{parent}/monthly_periods/",
    get: "periods/{parent}/monthly_periods/{id}/details",
    edit: "periods/{parent}/monthly_periods/{id}/update",
    delete: "periods/{parent}/monthly_periods/{id}/delete",
    authorize: "periods/{parent}/monthly_periods/{id}/authorize",
    close: "periods/{parent}/monthly_periods/{id}/close",
    current: "periods/monthly_periods/last",
  },
};

export default Periods_urls;
