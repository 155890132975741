import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/Login";
import store from "@/store/index.js";
import Office_routes from "@/router/office_routes.js";
import managers_route from "@/router/managers_route.js";
import period_routes from "@/router/periods";
import cib_routes from "@/router/cib";
import bg_tasks_routes from "@/router/bg_tasks";
import mamla_routes from "@/router/mamla_routes";
import app_setting_routes from "@/router/app_setting";
import report_routes from "@/router/report";
import nls from "@/router/nls";
import nlFileRoutes from "@/router/nl_file";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: LoginView,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
        children: [
            {
                path: "/administration/userlist",
                name: "UserList",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "UserList" */ "../views/administration/UserListPage"
                        ),
            },
            ...Office_routes,
            ...managers_route,
            ...period_routes,
            ...cib_routes,
            ...bg_tasks_routes,
            ...mamla_routes,
            ...app_setting_routes,
            ...report_routes,
            ...nls,
            ...nlFileRoutes
        ],
        beforeEnter(to, from, next) {
            // => using before enter
            let now = store.state.server_time;
            // console.log(now);
            if (now >= store.state.validTill) {
                store.commit("authenticate", false);
            }
            if (
                to.name !== "Login" &&
                !store.state.isAuthenticated &&
                now >= store.state.validTill
            ) {
                // console.log(store.state);
                next({name: "Login"});
            } else {
                // console.log(store.state);
                next();
            }
        },
    },
    {
        path: "/user/reset_password",
        name: "PasswordReset",
        component: () =>
            import(
                /* webpackChunkName: "UserDetails" */ "../views/ChangePasswordPage"
                ),
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    let now = store.state.server_time;
    if (now >= store.state.validTill) {
        store.commit("authenticate", false);
    }
    if (
        to.name !== "Login" &&
        !store.state.isAuthenticated &&
        now >= store.state.validTill
    ) {
        // console.log(store.state);
        // next({name: "Login"});
        next(false);
    } else {
        next();
    }
});
export default router;
