const mamla_routes = [
    {
        path: "/loan_mamla/add",
        name: "AddLoanMamla",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "AddLoanMamla" */ "@/views/mamla/AddLoanMamla"
                ),
    },
    {
        path: "/loan_mamla/:fileid",
        name: "LoanMamlaDetails",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanMamlaDetails" */ "@/views/mamla/LoanMamlaDetails"
                ),
    },
    {
        path: "/loan_mamla/",
        name: "LoanMamlaList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanMamlaList" */ "@/views/mamla/LoanMamlaList"
                ),
    },
    {
        path: "/loan_mamla/zone_wise",
        name: "LoanFileListZoneWise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanMamlaList" */ "@/views/mamla/LoanMamlaList"
                ),
    },
    {
        path: "/loan_mamla/circle_wise",
        name: "LoanFileListCircleWise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanMamlaList" */ "@/views/mamla/LoanMamlaList"
                ),
    },
    {
        path: "/loan_mamla/own_zone",
        name: "LoanFileListOwnZone",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanMamlaList" */ "@/views/mamla/LoanMamlaList"
                ),
    },
    {
        path: "/loan_mamla/own_circle",
        name: "LoanFileListOwnCircle",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanMamlaList" */ "@/views/mamla/LoanMamlaList"
                ),
    },
    {
        path: "/loan_mamla/all_file",
        name: "AllFileList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "LoanMamlaList" */ "@/views/mamla/LoanMamlaList"
                ),
    },
];

export default mamla_routes;
