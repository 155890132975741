<template>
  <v-app>
    <v-main class="grey lighten-1">
      <transition name="fade" mode="out-in" enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="animate__animated animate__fadeOut">
        <!--      <div class="animation">-->
        <!--        <lottie-animation-->
        <!--            :animationData="require('@/assets/lottie_files/data.json')"-->
        <!--            :loop="true"-->
        <!--            :autoPlay="true"-->
        <!--            :speed="1" class="animation">-->
        <v-img
            :src="require('@/assets/bg.webp')"

            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            max-height="100%"
            max-width="100%"
        >

          <!--        </lottie-animation>-->
          <!--      </div>-->
          <router-view/>
        </v-img>
      </transition>
    </v-main>
    <v-footer
        padless
        elevation="11"
        fixed
        inset
        class="text-center"
    >
      <v-row>
        <v-col cols="12">
          <v-card
              light
              class="text-center align-self-center elevation-11 d-block"
          >
            <v-card-text class="font-weight-bold" dark>
              Developed by <br/> IT Division<br/>Agrani Bank Limited
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data: () => ({
    //
  }),
};
</script>

<style>
.content {
  position: relative;
  z-index: 5;
}

.animation {
  position: absolute;
  align-items: center;
  /*width: 100%;*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #FFFF;
  object-fit: fill;
}

.footer {
  z-index: 5;
}
</style>