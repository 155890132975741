const nlFileRoutes = [
    {
        path: "/nl_file/subjects",
        name: "NlFileSubject",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "NlFileSubject" */ "@/views/nl_file/NlSubjectPage"
                ),
    },
    {
        path: "/nl_file/:fileid",
        name: "nlFileDetails",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "nlFileDetails" */ "@/views/nl_file/nlFileDetailsPage.vue"
                ),
    },
    // {
    //     path: "/nl_file",
    //     name: "nlFileDetails",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "nlFileDetails" */ "@/views/nl_file/nlFileListPage.vue"
    //             ),
    // },
    {
        path: "/own_branch",
        name: "nlFileListOwnBranch",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "nlFileListOwnBranch" */ "@/views/nl_file/nlFileListPage.vue"
                ),
    },
    {
        path: "/own_zone",
        name: "nlFileListOwnZone",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "nlFileListOwnZone" */ "@/views/nl_file/nlFileListPage.vue"
                ),
    },
    {
        path: "/own_circle",
        name: "nlFileListOwnCircle",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "nlFileListOwnCircle" */ "@/views/nl_file/nlFileListPage.vue"
                ),
    },
];

export default nlFileRoutes;
