<template>
  <v-container>
    <v-row justify="center" class="my-15">
      <v-col cols="6">
        <v-card class="elevation-11" color="blue lighten-5">
          <Login/>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="height: 90vh">
      <v-col cols="12">
        <!--        <WebViewer class="elevation-11"-->
        <!--                   initialDoc='./pdf_files/download.pdf'></WebViewer>-->
        <PdfViewer class="elevation-11"
                   pdf_path='./pdf_files/office_order.pdf'></PdfViewer>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import Login from '../components/Login'
import PdfViewer from "@/components/file_viewer/pdf_view_object"

export default {
  name: 'LoginView',

  components: {
    Login,
    // WebViewer,
    PdfViewer
  },

  mounted: () => {
    document.title = 'Agrani Bank Legal Mater System'
    window.scrollTo(0, 0);
  }
}
</script>
