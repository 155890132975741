const manager_routes = [
  {
    path: "/managers",
    name: "Managers",
    component: () =>
      import(
        /* webpackChunkName: "ManagerListPage" */ "@/views/administration/personnel/ManagersPage"
      ),
  },
  {
    path: "/personnel",
    name: "Personnel",
    component: () =>
      import(
        /* webpackChunkName: "PersonnelListPage" */ "@/views/administration/personnel/PersonnelPage"
      ),
  },
  {
    path: "/lawyers",
    name: "Lawyer",
    component: () =>
      import(
        /* webpackChunkName: "LawyerListPage" */ "@/views/administration/personnel/LawerPage"
      ),
  },
];

export default manager_routes;
