const Office_routes = [
  {
    path: "/office/branch",
    name: "Branches",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "BranchListPage" */ "@/views/offices/BranchListPage"
      ),
  },
  {
    path: "/office/circles",
    name: "Circles",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "CircleListPage" */ "@/views/offices/CircleListPage"
      ),
  },
  {
    path: "/office/zone",
    name: "Zones",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ZoneListPage" */ "@/views/offices/ZoneListPage"
      ),
  },
];

export default Office_routes;
