const nls = [
    {
        path: "/nls/subject",
        name: "NLSSubject",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "BranchListPage" */ "@/views/nls/NlsListPage"
                ),
    },
    {
        path: "/nls/owner",
        name: "NLSOwner",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "CircleListPage" */ "@/views/offices/CircleListPage"
                ),
    },
];

export default nls;
