const report_routes = [
    {
        path: "/report/all_branch/day_wise",
        name: "all_branch_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/all_branch/non_day_wise",
        name: "all_branch_non_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/all_zone/day_wise",
        name: "all_zone_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/all_zone/non_day_wise",
        name: "all_zone_non_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/all_circle/day_wise",
        name: "all_circle_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/all_circle/non_day_wise",
        name: "all_circle_non_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/head_office/day_wise",
        name: "head_office_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/head_office/non_day_wise",
        name: "head_office_non_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },

    {
        path: "/report/own_branch/day_wise",
        name: "own_branch_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/own_branch/non_day_wise",
        name: "own_branch_non_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/own_zone/day_wise",
        name: "own_zone_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/own_zone/non_day_wise",
        name: "own_zone_non_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/own_circle/day_wise",
        name: "own_circle_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
    {
        path: "/report/own_circle/non_day_wise",
        name: "own_circle_non_day_wise",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "ReportDayWise" */ "@/views/report/ReportViewDayWise.vue"
                ),
    },
]
export default report_routes;