const period_routes = [
  {
    path: "/yearly_periods",
    name: "Yearly",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "YearlyPeriod" */ "@/views/administration/periods/YearlyPeriodPage"
      ),
  },
];

export default period_routes;
