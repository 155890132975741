const bg_tasks_routes = [
  {
    path: "/bg_tasks",
    name: "bg_tasks",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "BgTasks" */ "@/views/administration/back_ground_tasks/bg_tasks"
      ),
  },
];

export default bg_tasks_routes;
