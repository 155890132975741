<template>
  <object type="application/pdf" :data="pdf_path" width="100%" height="100%"></object>
</template>

<script>
export default {
  name: "PdfViewer",
  props: ["pdf_path"],
  // data: () => ({pdf: "./pdf_files/download.pdf"}),
}
</script>